import { Dispatch } from 'redux';
import decode from 'jwt-decode';
import flow from 'lodash/flow';
import get from 'lodash/get';

import fetch from '@payhop/shared-utils/fetch.util';
import { Types as NotificationTypes } from './notifications.duck';

import { setError } from './error.duck';

const userStorage = sessionStorage.getItem('user') || null;

const user = userStorage && decode<any>(JSON.parse(userStorage).token);

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  token?: any;
};

export const Types = {
  RESET_ERROR: 'shared/authentication/RESET_ERROR',
  INIT: 'shared/authentication/INIT',
  SUCCESS: 'shared/authentication/SUCCESS',
  ERROR: 'shared/authentication/ERROR',
  LOGOFF: 'shared/authentication/LOGOFF',
};

const initialState: any = {
  data: user,
  signedIn: !!user,
  token: userStorage && JSON.parse(userStorage).token,
  loading: false,
  error: null,
};

export default (state = initialState, action: Actions) => {
  const { type, payload, error, token } = action;

  const reducers = {
    [Types.RESET_ERROR]: {
      ...state,
      error: null,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.ERROR]: {
      loading: false,
      data: null,
      signedIn: false,
      error,
    },
    [Types.SUCCESS]: {
      loading: false,
      data: payload,
      signedIn: true,
      error: false,
      token,
    },
    [Types.LOGOFF]: {
      loading: false,
      data: null,
      signedIn: false,
      error: null,
    },
  };

  return reducers[type] || state;
};

export const setUserObj = (user: any) =>
  sessionStorage.setItem('user', JSON.stringify(user));

export const removeUserObj = () => sessionStorage.removeItem('user');

export const resetError = () => ({
  type: Types.RESET_ERROR,
});

export const setAuth = (result: any) => async (dispatch: Dispatch) => {
  setUserObj(result);

  dispatch({
    type: Types.SUCCESS,
    token: result.token,
    payload: decode(result.token),
  });
};

export const login =
  (data: any, context: 'ec', origin = 1) =>
  async (dispatch: Dispatch<any>) => {
    const contextObj = {
      ec: {
        URL: `accounts/sign-in`,
        MS: 'ACCOUNT',
      },
    };

    try {
      dispatch({
        type: Types.INIT,
      });

      const response = await fetch(contextObj[context].URL, {
        method: 'POST',
        ms: contextObj[context].MS as any,
        body: JSON.stringify({
          ...data,
          origin,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch(setAuth(result));

      return result;
    } catch (result: any) {
      dispatch(
        setError({
          messages: result?.errors?.message,
          data: result,
          visible: false,
          context: 'login',
        })
      );

      return false;
    }
  };

export const logoff = () => async (dispatch: Dispatch) => {
  dispatch({
    type: NotificationTypes.RESET,
  });

  removeUserObj();

  return dispatch({ type: Types.LOGOFF });
};

export const acceptTerm =
  ({ check_term_partnership }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetch(`accounts/accept-term`, {
        auth: true,
        ms: 'ACCOUNT',
        method: 'POST',
        body: JSON.stringify({
          check_term_partnership,
        }),
      });

      if (!response.ok) {
        throw new Error();
      }

      const result = await response.json();

      dispatch(setAuth(result));

      return result;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const confirmCode =
  ({ channel, code }: any) =>
  async (dispatch) => {
    try {
      const response = await fetch(`accounts/confirm-code`, {
        auth: true,
        ms: 'ACCOUNT',
        method: 'POST',
        body: JSON.stringify({
          channel,
          code,
        }),
      });

      if (!response.ok) {
        throw new Error();
      }

      const result = await response.json();

      dispatch(setAuth(result));

      return result;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const resendConfirmation =
  ({ channel }: any) =>
  async () => {
    try {
      const response = await fetch(`accounts/send-code`, {
        auth: true,
        ms: 'ACCOUNT',
        method: 'POST',
        body: JSON.stringify({
          channel,
        }),
      });

      if (!response.ok) {
        throw new Error();
      }

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const forgotPassword = (email: string) => async (dispatch: Dispatch) => {
  try {
    const response = await fetch(`accounts/forgot-password`, {
      method: 'POST',
      ms: 'ACCOUNT',
      body: JSON.stringify({
        email,
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    return true;
  } catch (result: any) {
    dispatch(
      setError({
        messages: result?.errors?.message,
        data: result,
        visible: false,
        context: 'forgotPassword',
      })
    );

    return false;
  }
};

export const resetPassword =
  ({ email, token, newPassword }: any) =>
  async (dispatch: any) => {
    try {
      const response = await fetch(`accounts/reset-password`, {
        method: 'POST',
        ms: 'ACCOUNT',
        body: JSON.stringify({
          email,
          token,
          newPassword,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      return true;
    } catch (result: any) {
      dispatch(
        setError({
          messages: result?.errors?.message,
          data: result,
          visible: false,
          context: 'reset-password',
        })
      );

      return false;
    }
  };

export const changePassword =
  ({ newPassword, context = 'ec' }: any) =>
  async () => {
    try {
      const fetchObj: any = {
        ec: [
          'accounts/change-password',
          {
            method: 'POST',
            ms: 'ACCOUNT',
            auth: true,
            body: JSON.stringify({
              newPassword,
            }),
          },
        ],
      };

      const response = await fetch(fetchObj[context][0], fetchObj[context][1]);

      if (!response.ok) {
        throw new Error();
      }

      const result = await response.json();

      return result;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const refreshToken = () => async (dispatch) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`accounts/refresh-token`, {
      auth: true,
      ms: 'ACCOUNT',
      method: 'GET',
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch(setAuth(result));

    return true;
  } catch (e) {
    //
    dispatch({
      type: Types.ERROR,
    });

    return false;
  }
};

const selectRoot = (state: any) => get(state, 'shared.authentication');
const selectData = (state: any) => get(state, 'data');
const selectError = (state: any) => get(state, 'error');
const selectLoading = (state: any) => get(state, 'loading');
const selectSignedIn = (state: any) => get(state, 'signedIn');

export const Selectors = {
  data: flow(selectRoot, selectData),
  error: flow(selectRoot, selectError),
  loading: flow(selectRoot, selectLoading),
  signedIn: flow(selectRoot, selectSignedIn),
};
