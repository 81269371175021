import { Dispatch } from 'redux';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { setError } from '@payhop/shared-ducks/error.duck';

import fetch from '@payhop/shared-utils/fetch.util';

const context = 'domains/compliance';
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  filters?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  ERROR: `${context}/ERROR`,
};

const initialState: any = {
  data: null,
  loading: false,
  error: null,
  fetched: false,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: null,
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: false,
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: true,
    },
  };

  return reducers[type] || state;
};

export const getDispatch = () => async (dispatch: Dispatch<any>, getState) => {
  try {
    dispatch({
      type: Types.INIT,
    });

    const response = await fetch(`domains/business/compliance-term`, {
      method: 'GET',
      ms: 'DOMAIN',
      auth: true,
    });

    const result = await response.json();

    if (!response.ok) {
      throw result;
    }

    dispatch({
      type: Types.SUCCESS,
      payload: result,
    });

    return result;
  } catch (result: any) {
    dispatch({
      type: Types.ERROR,
    });

    return false;
  }
};

export const resetDispatch = () => ({
  type: Types.RESET,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectDataResults = (state: any) => get(state, 'results');
const selectDataMetadata = (state: any) => get(state, 'metadata');
const selectDataMetadataResultset = (state: any) => get(state, 'resultset');
const selectLoading = (state: any) => get(state, 'loading');
const selectFilters = (state: any) => get(state, 'filters');
const selectFetched = (state: any) => get(state, 'fetched');

export const Selectors = {
  data: flow(selectRoot, selectData),
  data_results: flow(selectRoot, selectData, selectDataResults),
  loading: flow(selectRoot, selectLoading),
  filters: flow(selectRoot, selectFilters),
  fetched: flow(selectRoot, selectFetched),
  resultset: flow(
    selectRoot,
    selectData,
    selectDataMetadata,
    selectDataMetadataResultset
  ),
};

export const Dispatchs = {
  get: getDispatch,
  reset: resetDispatch,
};
