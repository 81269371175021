import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box } from '@material-ui/core';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import * as creditorDuck from '@wl-onboarding/ducks/creditor.duck';

import useQuery from '@payhop/shared-hooks/useQuery.hook';

const AuthByTokenScreenContainer = () => {
  const handleNotification = useNotification();
  const dispatch = useDispatch();
  const token: any = useQuery().get('token');

  const selectorRedux = {
    error: useSelector(creditorDuck.Selectors.error),
    loading: useSelector(creditorDuck.Selectors.loading),
  };

  const [hasError, payloadError] = selectorRedux.error;

  const handleGetToken = useCallback(async () => {
    dispatch(creditorDuck.Dispatchs.get(token));
  }, [token]);

  useEffect(() => {
    handleGetToken();
  }, [handleGetToken]);

  useEffect(() => {
    if (hasError === true) {
      handleNotification(payloadError?.errors?.message?.[0], 'error', {
        autoHideDuration: null,
        action: null,
      });
    }
  }, [selectorRedux.error]);

  return (
    <div
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {selectorRedux.loading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={16}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default AuthByTokenScreenContainer;
