import { combineReducers } from 'redux';

type listOfReducersType = {
  name: string;
  path: string;
};

const reducers = {};

const listOfReducers: Array<listOfReducersType> = [
  {
    name: 'acquirers',
    path: 'acquirers.duck',
  },
  {
    name: 'advancements',
    path: 'advancements.duck',
  },
  {
    name: 'compliance',
    path: 'compliance.duck',
  },
];

listOfReducers.map((elm) =>
  Object.assign(reducers, {
    ...reducers,
    [elm.name]: require(`./${elm.path}`).default,
  })
);

export default combineReducers({
  ...reducers,
});
