const EventEmitter: {
  events: any;
  dispatch: (event: any, data?: any) => void;
  subscribe: (event: any, callback: any) => void;
} = {
  events: {},
  dispatch: function (event, data) {
    if (!this.events[event]) return;

    this.events[event].forEach((cb: any) => cb(data));
  },
  subscribe: function (event, callback) {
    let eventArray = event;

    if (!Array.isArray(event)) {
      eventArray = [event];
    }

    eventArray.map((ev: any) => {
      if (!this.events[ev]) this.events[ev] = [];

      return this.events[ev].push(callback);
    });
  },
};

export default EventEmitter;
