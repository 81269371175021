import { useSelector } from 'react-redux';

const useUserAuthenticated = () => {
  const authentication = useSelector(
    ({ shared }: any) => shared.authentication
  );

  return authentication;
};

export default useUserAuthenticated;
