export const colors = {
  black: '#0D1421',
  primary: '#3269C3',
  gray: '#B4B4B9',
  gray3: '#828282',
  purple: '#3269C3',
  success: '#2CD8C5',
};

export const newBrand = {
  primary: '#000E2B',
  secondary: '#2dad9c',
  purple: '#4928a6',
  gray: '#828788',
  gray2: '#FAFAFA',
  gray3: '#E5E9EA',
  success: '#2dad9c',
  white: '#FFFFFF',
};
