import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={css`
          @media (max-width: 500px) {
            .MuiCollapse-wrapper {
              justify-content: center;
            }
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
