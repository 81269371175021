import { API_URL_WITH_MS } from '@payhop/shared-configs/api.config';

import { removeUserObj as removeUserObjEc } from '@payhop/shared-ducks/authentication.duck';

import getToken from './getToken.util';

const discoverLogoffFn = () => {
  const pathName = window.location.pathname;

  const isLoginPage = pathName.split('/')[1] === 'login';

  if (isLoginPage) {
    return false;
  }

  return removeUserObjEc;
};

type configType = {
  ms:
    | 'ACCOUNT'
    | 'MERCHANT'
    | 'DEAL'
    | 'NOTIFICATION'
    | 'DOMAIN'
    | 'CHECKID'
    | 'REQUEST'
    | 'CONTRACT'
    | 'CREDITOR'
    | 'SCHEDULE'
    | 'EXPORT'
    | 'UPLOAD'
    | 'RECON_IMPORT'
    | 'RECON_ACCOUNT'
    | 'RECON_DOMAIN'
    | 'RECON_MATCH'
    | 'RECON_MONITORING'
    | 'SCHEDULE'
    | 'API';
  method?: 'POST' | 'PATCH' | 'GET' | 'DELETE' | 'PUT';
  auth?: boolean;
  bearer?: boolean;
  defaultHeaders?: boolean;
  [key: string]: any;
};

const fetch = async (url: string, config: configType) => {
  const {
    auth,
    ms,
    bearer = true,
    defaultHeaders = true,
    ...restConfig
  } = config;
  const token = getToken();

  const logoff = discoverLogoffFn();

  const authString = [bearer && 'Bearer', token].filter(Boolean).join(' ');

  const result = await window.fetch(
    `${API_URL_WITH_MS(ms)}/${url}`,
    Object.assign({}, restConfig, {
      headers: new Headers(
        Object.assign(
          defaultHeaders && {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          auth && {
            Authorization: authString,
          },
          restConfig.headers
        )
      ),
    })
  );

  const isUnauthorized = result.status === 401;

  if (isUnauthorized && !!logoff) {
    logoff();

    window.location.reload();

    return result;
  }

  return result;
};

export default fetch;
