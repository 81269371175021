import React from 'react';
import flow from 'lodash/flow';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  Dialog,
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useUserAuthenticated from '@payhop/shared-hooks/useUserAuthenticated.hook';

import * as dialogShowInfosDuck from '@wl-onboarding/ducks/dialogs/dialogShowInfos.duck';

const GridFields = ({ grid }) => {
  return (
    <Box display="grid" gridGap={16}>
      {grid?.map((g, idx) => (
        <Box
          display="grid"
          key={idx}
          gridTemplateColumns={`repeat(${Array.from(g).length}, 1fr)`}
          gridGap={16}
        >
          {g?.map(({ customComponent, ...gr }, index) =>
            React.createElement(customComponent || TextField, {
              variant: 'outlined',
              inputProps: { readOnly: true },
              fullWidth: true,
              key: index,
              ...gr,
            })
          )}
        </Box>
      ))}
    </Box>
  );
};

const DialogShowInfosComponent = () => {
  const dispatch = useDispatch();

  const currentUser = useUserAuthenticated()?.data || {};

  const dispatchRedux = {
    CLOSE: flow(dialogShowInfosDuck.Dispatchs.close, dispatch),
  };

  const selectorRedux: any = {
    visible: useSelector(dialogShowInfosDuck.Selectors.visible),
    payload: useSelector(dialogShowInfosDuck.Selectors.payload),
  };

  const fields = {
    cnpj: {
      label: 'CNPJ',
      value: currentUser?.documentNumber,
      customComponent: NumberFormat,
      customInput: TextField,
      format: '##.###.###/####-##',
    },
    razao_social: {
      label: 'Razão social',
      value: currentUser?.companyName,
    },
    nome_fantasia: {
      label: 'Nome Fantasia',
      value: currentUser?.tradingName,
    },
    endereco: {
      label: 'Endereço',
      value: currentUser?.address,
    },
    nome: {
      label: 'Nome',
      value: currentUser?.name,
    },
    email: {
      label: 'Email',
      value: currentUser?.Email,
    },
    telefone: {
      label: 'Telefone',
      value: currentUser?.phoneNumber,
      customComponent: NumberFormat,
      customInput: TextField,
      format: '(##) #####-####',
    },
  };

  const gridDadosCadastrais = [
    [fields.cnpj],
    [fields.razao_social],
    [fields.nome_fantasia],
    [fields.endereco],
  ];

  const gridDadosDoContato = [[fields.nome], [fields.email], [fields.telefone]];

  return (
    <Dialog
      open={selectorRedux.visible as any}
      onClose={() => dispatchRedux.CLOSE()}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
    >
      <Box p={3} position="relative" pt={6} minWidth={800}>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={() => dispatchRedux.CLOSE()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="grid" gridTemplateColumns="1fr" gridGap={24} mt={3}>
          <Box display="grid" gridTemplateColumns={'1fr'} gridGap={16}>
            <Typography variant="h5" css={{ fontWeight: 500 }}>
              Dados cadastrais
            </Typography>

            <GridFields grid={gridDadosCadastrais} />
          </Box>
          <Box display="grid" gridTemplateColumns={'1fr'} gridGap={16}>
            <Typography variant="h6" css={{ fontWeight: 500 }}>
              Dados do contato
            </Typography>

            <GridFields grid={gridDadosDoContato} />
          </Box>
          {/* <Typography variant="body2" align="center">
            Em caso de dúvidas entre em contato com a PayHop através do <br />
            <Link href="tel:+5511992335000">(11) 99233-5000</Link> ou{' '}
            <Link href="mailto:atendimento@payhop.com.br">
              atendimento@payhop.com.br
            </Link>
          </Typography> */}
          <Box justifySelf="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispatchRedux.CLOSE()}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogShowInfosComponent;
