import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import ROUTES from '@wl-onboarding/constants/routes.constant';

import * as formDataDuck from '@wl-onboarding/ducks/formData.duck';

import FieldDocument from '@wl-onboarding/components/Fields/FieldDocument.component';
import FieldPhone from '@wl-onboarding/components/Fields/FieldPhone.component';

import validateCNPJ from '@payhop/shared-utils/validateCNPJ.util';
import validateCPF from '@payhop/shared-utils/validateCPF.util';
import messages from '@payhop/shared-utils/messagesSchema.util';

const validationSchema = Yup.object({
  full_name: Yup.string().nullable().required(messages.REQUIRED),
  company_position: Yup.string().nullable().required(messages.REQUIRED),
  phone_number: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
    .min(10, messages.MIN),
  email: Yup.string().nullable().required(messages.REQUIRED),
  isLegal: Yup.boolean().oneOf([true]),
  isTrust: Yup.boolean().oneOf([true]),
  document_number: Yup.string()
    .nullable()
    .required(messages.REQUIRED)
    .test('document_number', 'Documento inválido', (value) => {
      const documentLength = !!value && String(value).length;

      if (documentLength === 11 && validateCPF(value)) {
        return true;
      }

      if (documentLength === 14 && validateCNPJ(value)) {
        return true;
      }

      return false;
    }),
});

const FormRegistrationComponent = () => {
  const handleNotification = useNotification();
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    updatePartners: flow(formDataDuck.Dispatchs.updatePartners, dispatch),
    updateData: flow(formDataDuck.Dispatchs.updateData, dispatch),
  };

  const selectorRedux = {
    data: useSelector(formDataDuck.Selectors.data),
    loading: useSelector(formDataDuck.Selectors.loading),
    error: useSelector(formDataDuck.Selectors.error),
  };

  const handleContinue = async (values) => {
    dispatchRedux.updateData('registration', values);

    dispatchRedux.updatePartners();

    return true;
  };

  React.useEffect(() => {
    // const { advancementChecked } = selectorRedux.dataUser;
    // if (advancementChecked === 'True') {
    //   history.push(ROUTES.TERM);
    // }
  }, []);

  React.useEffect(() => {
    const [hasError, payloadError] = selectorRedux.error || [];

    if (hasError === false) {
      history.push(ROUTES.SELFIE);
    }

    if (hasError === true) {
      handleNotification(payloadError?.errors?.message?.[0], 'error');
    }
  }, [selectorRedux.error]);

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={24}>
      <Formik
        onSubmit={handleContinue}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnMount
        initialValues={{
          full_name: selectorRedux.data.registration.full_name,
          document_number: selectorRedux.data.registration.document_number,
          company_position: selectorRedux.data.registration.company_position,
          phone_number: selectorRedux.data.registration.phone_number,
          email: selectorRedux.data.registration.email,
          isLegal: selectorRedux.data.registration.isLegal,
          isTrust: selectorRedux.data.registration.isTrust,
        }}
      >
        {({ errors, submitForm, setFieldValue, values }) => (
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Field
              name="full_name"
              component={TextField}
              label="Nome Completo"
              fullWidth
              variant="outlined"
            />
            <FieldDocument
              name="document_number"
              label="CPF"
              isAllowed={(values) => {
                const { value } = values;

                return value.length <= 11;
              }}
            />
            <Field
              name="company_position"
              component={TextField}
              label="Cargo"
              fullWidth
              variant="outlined"
            />
            <FieldPhone name="phone_number" />
            <Field
              name="email"
              component={TextField}
              label="Email"
              fullWidth
              variant="outlined"
            />

            <Box display="flex" flexDirection="column" gridGap={8}>
              <FormControlLabel
                label="Sou representante legal da empresa"
                control={
                  <Checkbox
                    checked={values.isLegal}
                    onChange={({ target: { checked } }) =>
                      setFieldValue('isLegal', checked)
                    }
                  />
                }
              />

              <FormControlLabel
                label="Confirmo que as informações acima são verdadeiras"
                control={
                  <Checkbox
                    checked={values.isTrust}
                    onChange={({ target: { checked } }) =>
                      setFieldValue('isTrust', checked)
                    }
                  />
                }
              />
            </Box>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={Object.keys(errors).length > 0 || selectorRedux.loading}
              onClick={submitForm}
            >
              {selectorRedux.loading && (
                <CircularProgress color="inherit" size={28} />
              )}

              {!selectorRedux.loading && <span>Continuar</span>}
            </Button>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default FormRegistrationComponent;
