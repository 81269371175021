/* eslint-disable */

import fetch from '@payhop/shared-utils/fetch.util';
import { v4 } from 'uuid';

const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

type dataRequest = {
  type: '1' | '2' | '3';
  info: string;
  files?: any;
  filesArrBase64?: any;
  accountId?: string;
};

export default async ({
  type,
  info = '',
  files,
  filesArrBase64,
  accountId,
}: dataRequest) => {
  let formData = new FormData();

  if (files) {
    formData.append('files', files);
  }

  if (filesArrBase64) {
    filesArrBase64.map((f: any) =>
      formData.append('files', dataURLtoFile(f, `${v4()}.jpg`))
    );
  }

  formData.append('file_type', type);
  formData.append('file_info', info);

  if (accountId) {
    formData.append('account_id', accountId);
  }

  const response = await fetch(`checkids`, {
    method: 'POST',
    ms: 'CHECKID',
    defaultHeaders: false,
    auth: true,
    body: formData,
  });

  const result = await response.json();

  return [response.ok, result];
};
