const ROOT = '/';
const REGISTER = '/dados-cadastrais';
const SELFIE = '/selfie';
const ACQUIRERS = '/credenciadoras';
const TERM = '/aceite-do-termo';
const FINISH = '/finalizacao';
const AUTH_BY_TOKEN = '/auth-by-token';

const ROUTES = {
  ROOT,
  REGISTER,
  SELFIE,
  ACQUIRERS,
  TERM,
  FINISH,
  AUTH_BY_TOKEN,
};

export default ROUTES;
