import React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';

const FieldDocumentComponent = ({ name, ...restProps }) => {
  const Component = ({
    field,
    form: { setFieldValue, setFieldTouched },
    meta: { error, touched },
  }: any) => {
    const lenghtValue = String(field.value)?.length;

    const handleFormatCNPJ = (value) => {
      let formatedCNPJ = value;
      formatedCNPJ = formatedCNPJ.replace(/\D/g, '');
      formatedCNPJ = formatedCNPJ.replace(/^(\d{2})(\d)/, '$1.$2');
      formatedCNPJ = formatedCNPJ.replace(/^(\d{2}).(\d{3})(\d)/, '$1.$2.$3');
      formatedCNPJ = formatedCNPJ.replace(/.(\d{3})(\d)/, '.$1/$2');
      formatedCNPJ = formatedCNPJ.replace(/(\d{4})(\d)/, '$1-$2');

      return formatedCNPJ;
    };

    const handleFormatCPF = (value) => {
      let formatedCPF = value;
      formatedCPF = formatedCPF.replace(/\D/g, '');
      formatedCPF = formatedCPF.replace(/(\d{3})(\d)/, '$1.$2');
      formatedCPF = formatedCPF.replace(/(\d{3})(\d)/, '$1.$2');
      formatedCPF = formatedCPF.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

      return formatedCPF;
    };

    const handleFormatDocument = (value) => {
      if (value.length <= 14) {
        if (value.length > 12) {
          return handleFormatCNPJ(value);
        } else {
          return handleFormatCPF(value);
        }
      }
    };

    return (
      <>
        <NumberFormat
          customInput={TextField}
          format={handleFormatDocument}
          fullWidth
          label="CNPJ ou CPF"
          variant="outlined"
          value={field.value}
          error={touched && !!error}
          helperText={touched && !!error && error}
          onBlur={async ({ target: { value } }) => {
            setFieldTouched(field.name, true);
          }}
          onValueChange={(values: any) =>
            setFieldValue(field.name, values.value)
          }
          {...restProps}
        />
      </>
    );
  };

  return <Field name={name}>{Component}</Field>;
};

export default FieldDocumentComponent;
