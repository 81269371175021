import fetch from '@payhop/shared-utils/fetch.util';

type Body = {
  acquirers: Array<{
    acquirer_id: string;
    advancement_id: string;
  }>;
};

export default async function patchMerchantsIdAcquirersService(
  id,
  payload: Body
) {
  const url = [`merchants/${id}/acquirers`].join('');

  const response = await fetch(url, {
    method: 'PATCH',
    ms: 'MERCHANT',
    auth: true,
    body: JSON.stringify(payload),
  });

  const result = await response.json();

  return [response.ok, result];
}
