import React, { useState } from 'react';
import { TextField, Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import UploadSelfie from '@wl-onboarding/components/UploadSelfie.component';

import ROUTES from '@wl-onboarding/constants/routes.constant';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';
import * as formDataDuck from '@wl-onboarding/ducks/formData.duck';
import * as authentication from '@payhop/shared-ducks/authentication.duck';

const validationSchema = Yup.object({
  selfie: Yup.object({
    files: Yup.array().required().default([]).min(3),
  }),
});

const FormDocumentationSelfieComponent = () => {
  const history = useHistory();

  const [start, setStart] = useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
    updateData: flow(formDataDuck.Dispatchs.updateData, dispatch),
    refreshToken: flow(authentication.refreshToken, dispatch),
  };

  const selectorRedux = {
    dataUser: useSelector(authentication.Selectors.data),
  };

  React.useEffect(() => {
    const { selfieChecked } = selectorRedux.dataUser;

    if (selfieChecked === 'True') {
      history.push(ROUTES.ACQUIRERS);
    }
  }, [selectorRedux.dataUser]);

  const handleContinue = async (values) => {
    dispatchRedux.updateData('documentation', values);

    await dispatchRedux.refreshToken();

    return true;
  };

  const handleStart = () => setStart(true);

  const { name } = selectorRedux.dataUser;

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={24}>
      {start === false && (
        <>
          <Box display="flex" flexDirection={'column'}>
            <Typography variant="h6">
              {name}, chegou a hora de nos enviar a sua selfie. E aqui vão
              algumas dicas:
            </Typography>
            <ul>
              <li>
                <Typography>
                  Retire os acessórios (óculos, boné, máscara...).
                </Typography>
              </li>
              <li>
                <Typography>Esteja em um local com boa iluminação.</Typography>
              </li>
            </ul>
            <Typography>
              Você não precisará apertar nenhum botão, apenas enquadrar o seu
              rosto na câmera e seguir as orientações da tela.
            </Typography>
          </Box>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleStart}
          >
            Começar
          </Button>
        </>
      )}

      {start === true && (
        <>
          <Formik
            initialValues={{ ...validationSchema.cast(undefined) }}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={handleContinue}
          >
            {({ errors, submitForm, values }) => (
              <>
                <UploadSelfie name="selfie" handleNext={submitForm} />
              </>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};

export default FormDocumentationSelfieComponent;
