import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';

import { FINISH } from '@wl-onboarding/constants/steps.constant';

import BubbleLayout from '@wl-onboarding/layouts/Bubble.layout';

import useProtectedByAuth from '@wl-onboarding/hooks/useProtectedRoute.hook';

import redirectToAppUtil from '@wl-onboarding/utils/redirectToApp.util';

import * as creditorDuck from '@wl-onboarding/ducks/creditor.duck';

const FinishScreen = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
  };

  const selectorRedux = {
    currentStep: useSelector(stepsDuck.Selectors.currentStep),
  };

  const selectorCreditorRedux = {
    data: useSelector(creditorDuck.Selectors.data),
  };

  React.useEffect(() => {
    const currentStepIsAccess = String(selectorRedux.currentStep).includes(
      'FINISH'
    );

    if (!currentStepIsAccess) {
      dispatchRedux.updateStep(FINISH.SUCCESS);
    }
  }, []);

  return (
    <BubbleLayout>
      <Box display={'flex'} flexDirection={'column'} gridGap={24}>
        {selectorRedux.currentStep === FINISH.SUCCESS && (
          <Box display="flex" gridGap={32} flexDirection={'column'}>
            <Box display="grid" gridTemplateColumns="1fr" gridGap={8}>
              <Typography variant="h6">Pronto!</Typography>
              <Typography variant="body1">
                Agora iremos analisar os arquivos enviados e em até 1 dia útil
                seu cadastro será ativado.
              </Typography>
              <Typography variant="body1">
                Caso ocorra algum problema enviaremos um e-mail informando quais
                passos deverão ser refeitos.
              </Typography>
            </Box>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                ((window as any).location.href =
                  selectorCreditorRedux.data?.path_url)
              }
            >
              Concluir
            </Button>
          </Box>
        )}

        {selectorRedux.currentStep === FINISH.TERM && (
          <>
            <Box display="flex" gridGap={8} flexDirection="column">
              <Typography variant="h6">
                Infelizmente não poderemos prosseguir. Mas agradecemos o seu
                interesse até aqui!
              </Typography>

              <Typography>
                Saiba mais sobre a PayHop acessando o nosso site payhop.com.br e
                nas redes sociais.
              </Typography>
            </Box>

            <Box display="flex" flexDirection={'column'} gridGap={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  ((window as any).location.href =
                    process.env.REACT_APP_URL_SITE)
                }
              >
                Finalizar
              </Button>
            </Box>
          </>
        )}

        {selectorRedux.currentStep === FINISH.LEGAL && (
          <>
            <Box display="flex" gridGap={8} flexDirection="column">
              <Typography variant="h6">Agora é só aguardar!</Typography>

              <Typography>
                Enviamos um e-mail ao representante legal, conforme indicado,
                para que ele(a) <b>autorize</b> o cadastro.
              </Typography>

              <Typography>
                Assim que o cadastro for concluído, você será notificado por
                e-mail.
              </Typography>
            </Box>

            <Box display="flex" flexDirection={'column'} gridGap={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => redirectToAppUtil()}
              >
                Finalizar
              </Button>
            </Box>
          </>
        )}
      </Box>
    </BubbleLayout>
  );
};

export default FinishScreen;
