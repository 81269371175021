export default {
  REQUIRED: 'Campo obrigatório',
  EMAIL: 'Você deve digitar um email válido',
  MIN: 'O campo necessita de no mínimo ${min} dígitos',
  MAX: 'O campo necessita de no máximo ${max} dígitos',
  PASSWORD_VERIFICATION_EQUALS: 'As senhas não conferem',
  PASSWORD_SPECS:
    'A senha deve conter pelo menos 8 caracteres, uma letra maíscula e minúscula, um número e um caractere especial',
  INT: 'O campo aceita somente valores inteiros',
};
