import { Dispatch } from 'redux';
import flow from 'lodash/flow';
import get from 'lodash/get';

import fetch from '@payhop/shared-utils/fetch.util';

import * as authentication from '@payhop/shared-ducks/authentication.duck';

import ROUTES from '@wl-onboarding/constants/routes.constant';

import getHistoryUtil from '@wl-onboarding/utils/getHistory.util';

import postAuthTokenService from '@wl-onboarding/services/postAuthToken.service';

const history = getHistoryUtil();

const context = 'creditor';
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  filters?: any;
};

export const Types = {
  INIT: `${context}/INIT`,
  SUCCESS: `${context}/SUCCESS`,
  RESET: `${context}/RESET`,
  ERROR: `${context}/ERROR`,
};

const creditorSession = window.sessionStorage.getItem('creditor');

const initialState: any = {
  data: creditorSession ? JSON.parse(creditorSession) : null,
  loading: false,
  error: [null, null],
  fetched: false,
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.RESET]: {
      ...initialState,
    },
    [Types.INIT]: {
      ...state,
      error: [null, null],
      loading: true,
    },
    [Types.SUCCESS]: {
      ...state,
      loading: false,
      error: [false, null],
      data: payload,
      fetched: true,
    },
    [Types.ERROR]: {
      ...state,
      loading: false,
      error: [true, payload],
    },
  };

  return reducers[type] || state;
};

export const getDispatch =
  (token) => async (dispatch: Dispatch<any>, getState) => {
    try {
      dispatch({
        type: Types.INIT,
      });

      const [successAuth, resultAuth] = await postAuthTokenService(token);

      if (!successAuth) {
        throw resultAuth;
      }

      const { token: tokenAuth } = resultAuth;

      dispatch(authentication.setAuth({ token: tokenAuth }));

      const { creditorId } = flow(getState, authentication.Selectors.data)();

      const response = await fetch(`creditors/${creditorId}/visual-identity`, {
        method: 'GET',
        ms: 'CREDITOR',
        auth: true,
      });

      const result = await response.json();

      if (!response.ok) {
        throw result;
      }

      dispatch({
        type: Types.SUCCESS,
        payload: result,
      });

      window.sessionStorage.setItem('creditor', JSON.stringify(result));

      history.replace(ROUTES.REGISTER);

      return result;
    } catch (result: any) {
      dispatch({
        type: Types.ERROR,
        payload: result,
      });

      return false;
    }
  };

export const resetDispatch = () => ({
  type: Types.RESET,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectDataResults = (state: any) => get(state, 'results');
const selectDataMetadata = (state: any) => get(state, 'metadata');
const selectDataMetadataResultset = (state: any) => get(state, 'resultset');
const selectLoading = (state: any) => get(state, 'loading');
const selectFilters = (state: any) => get(state, 'filters');
const selectFetched = (state: any) => get(state, 'fetched');
const selectError = (state: any) => get(state, 'error');

export const Selectors = {
  data: flow(selectRoot, selectData),
  error: flow(selectRoot, selectError),
  data_results: flow(selectRoot, selectData, selectDataResults),
  loading: flow(selectRoot, selectLoading),
  filters: flow(selectRoot, selectFilters),
  fetched: flow(selectRoot, selectFetched),
  resultset: flow(
    selectRoot,
    selectData,
    selectDataMetadata,
    selectDataMetadataResultset
  ),
};

export const Dispatchs = {
  get: getDispatch,
  reset: resetDispatch,
};
