import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    (
      message: string,
      variant: 'success' | 'error' | 'info' | 'warning' | 'default',
      opts = {}
    ) =>
      enqueueSnackbar(message, {
        variant,
        autoHideDuration: variant === 'error' ? 6000 : 3000,
        action: (key: any) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <span style={{ color: 'white' }}>Fechar</span>
          </Button>
        ),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        ...opts,
      }),
    [enqueueSnackbar, closeSnackbar]
  );
};

export default useNotification;
