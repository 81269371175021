import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  MenuItem,
  TextField as TextFieldMaterial,
} from '@material-ui/core';

import ROUTES from '@wl-onboarding/constants/routes.constant';

import messages from '@payhop/shared-utils/messagesSchema.util';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';
import * as advancementsDuck from '@wl-onboarding/ducks/domains/advancements.duck';
import * as formDataDuck from '@wl-onboarding/ducks/formData.duck';
import * as acquirersDuck from '@wl-onboarding/ducks/domains/acquirers.duck';
import * as authentication from '@payhop/shared-ducks/authentication.duck';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

const validationSchema = Yup.object({
  acquirers: Yup.array()
    .min(1)
    .of(
      Yup.object({
        acquirer_id: Yup.string().required(messages.REQUIRED).nullable(),
        advancement_id: Yup.string().required(messages.REQUIRED).nullable(),
      })
    ),
});

const FormAcquirersAntecipationComponent = () => {
  const handleNotification = useNotification();
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
    get: flow(advancementsDuck.Dispatchs.get, dispatch),
    updateData: flow(formDataDuck.Dispatchs.updateData, dispatch),
    resetError: flow(formDataDuck.Dispatchs.resetError, dispatch),
    updateAcquirers: flow(formDataDuck.Dispatchs.updateAcquirers, dispatch),
  };

  const selectorRedux = {
    data: useSelector(advancementsDuck.Selectors.data),
    dataAcquirers: useSelector(acquirersDuck.Selectors.data),
    dataForm: useSelector(formDataDuck.Selectors.data),
    dataUser: useSelector(authentication.Selectors.data),
    error: useSelector(formDataDuck.Selectors.error),
    loading: useSelector(formDataDuck.Selectors.loading),
  };

  const handleContinue = async (values) => {
    dispatchRedux.updateData('acquirers', values.acquirers, { force: true });

    dispatchRedux.updateAcquirers();

    return true;
  };

  React.useEffect(() => {
    dispatchRedux.get();

    return () => {
      dispatchRedux.resetError();
    };
  }, []);

  React.useEffect(() => {
    const { advancementChecked } = selectorRedux.dataUser;

    if (advancementChecked === 'True') {
      history.push(ROUTES.TERM);
    }
  }, []);

  React.useEffect(() => {
    const [hasError, payloadError] = selectorRedux.error || [];

    if (hasError === false) {
      history.push(ROUTES.TERM);
    }

    if (hasError === true) {
      handleNotification(payloadError?.errors?.message?.[0], 'error');
    }
  }, [selectorRedux.error]);

  const getAcquirerById = (acquirerId) =>
    selectorRedux?.dataAcquirers?.find((d) => d.id === acquirerId);

  const acquirerIdByToken = () =>
    selectorRedux?.dataUser?.acquirerId?.reduce((acc, curr) => {
      if (curr !== '') {
        return [
          ...acc,
          {
            acquirer_id: curr,
            advancement_id: '',
          },
        ];
      } else {
        return [];
      }
    }, []);

  const hasArrAcquirer =
    selectorRedux?.dataForm?.acquirers[0].acquirer_id !== null;

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={24}>
      <Typography align="center" variant="h6">
        Da(s) maquininha(s) que você informou, nos informe com qual(is) dela(s)
        você possui a antecipação dos recebíveis pré-contratada.
      </Typography>

      <Formik
        onSubmit={handleContinue}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnMount
        initialValues={{
          acquirers: hasArrAcquirer
            ? selectorRedux?.dataForm?.acquirers
            : acquirerIdByToken(),
        }}
      >
        {({ errors, submitForm, isSubmitting, values, setFieldValue }) => (
          <>
            <Box display="grid" gridTemplateColumns="1fr" gridGap={16}>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={8}>
                <Typography>Credenciadora(s)</Typography>
                <Typography>Antecipação</Typography>
              </Box>

              {values.acquirers.map((a, index) => (
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap={8}
                  key={a.acquirer_id}
                  alignItems="center"
                >
                  <TextFieldMaterial
                    value={getAcquirerById(a.acquirer_id)?.acquirer_name}
                    disabled
                    fullWidth
                    variant="outlined"
                  />

                  <Field
                    component={TextField}
                    name={`acquirers[${index}].advancement_id`}
                    label="Antecipação"
                    fullWidth
                    variant="outlined"
                    select
                    css={{ flex: 1 }}
                  >
                    {selectorRedux?.data?.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.description}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
              ))}
            </Box>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={Object.keys(errors).length > 0 || selectorRedux.loading}
              onClick={submitForm}
            >
              {selectorRedux.loading && (
                <CircularProgress color="inherit" size={28} />
              )}

              {!selectorRedux.loading && <span>Continuar</span>}
            </Button>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default FormAcquirersAntecipationComponent;
