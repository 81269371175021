export const Types = {
  SET_ERROR: 'shared/error/SET_ERROR',
  RESET_ERROR: 'shared/error/RESET_ERROR',
};

const initialState: any = {
  error: false,
  visible: false,
  context: null,
  messages: [],
  data: null,
};

export default (state = initialState, action: any) => {
  const { type, payload, messages, visible, context } = action;

  const reducers = {
    [Types.SET_ERROR]: {
      error: true,
      visible,
      context,
      messages: messages,
      data: payload,
    },
    [Types.RESET_ERROR]: {
      ...state,
      error: false,
      visible: false,
    },
  };

  return reducers[type] || state;
};

export const setError = ({ messages, data, visible = true, context }: any) => ({
  type: Types.SET_ERROR,
  messages: Array.isArray(messages) ? messages : [messages],
  payload: data,
  visible,
  context,
});

export const resetError = () => ({
  type: Types.RESET_ERROR,
});
