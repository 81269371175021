import flow from 'lodash/flow';
import get from 'lodash/get';

const context = 'steps';
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
  error?: any;
  step?: string;
};

export const Types = {
  UPDATE_STEP: `${context}/UPDATE_STEP`,
  FETCH_START: `${context}/FETCH_START`,
  FETCH_END: `${context}/FETCH_END`,
  FETCH_ERROR: `${context}/FETCH_ERROR`,
};

const initialState: any = {
  currentStep: null,
  loading: null,
  error: [null, null],
};

export default (state = initialState, action: Actions) => {
  const { type, payload, step } = action;

  const reducers = {
    [Types.UPDATE_STEP]: {
      ...state,
      currentStep: step,
    },
    [Types.FETCH_START]: {
      ...state,
      loading: true,
    },
    [Types.FETCH_END]: {
      currentStep: step || state.currentStep,
      loading: false,
      error: [false, null],
    },
    [Types.FETCH_ERROR]: {
      ...state,
      loading: false,
      error: [true, payload],
    },
  };

  return reducers[type] || state;
};

export const updateStepDispatch = (step) => ({
  type: Types.UPDATE_STEP,
  step,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectCurrentStep = (state: any) => get(state, 'currentStep');
const selectLoading = (state: any) => get(state, 'loading');
const selectError = (state: any) => get(state, 'error');

export const Selectors = {
  loading: flow(selectRoot, selectLoading),
  error: flow(selectRoot, selectError),
  currentStep: flow(selectRoot, selectCurrentStep),
};

export const Dispatchs = {
  updateStep: updateStepDispatch,
};
