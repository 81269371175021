import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { flow } from 'lodash';

import BubbleLayout from '@wl-onboarding/layouts/Bubble.layout';

import useProtectedByAuth from '@wl-onboarding/hooks/useProtectedRoute.hook';

import { ACQUIRERS } from '@wl-onboarding/constants/steps.constant';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';

import FormAcquirersSelectComponent from '@wl-onboarding/components/Form/FormAcquirers/FormAcquirersSelect.component';
import FormAcquirersAntecipationComponent from '@wl-onboarding/components/Form/FormAcquirers/FormAcquirersAntecipation.component';

const AcquirersScreen = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const selectorRedux = {
    currentStep: useSelector(stepsDuck.Selectors.currentStep),
  };

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
  };

  useEffect(() => {
    const currentStepIsAccess = String(selectorRedux.currentStep).includes(
      'ACQUIRERS'
    );

    if (!currentStepIsAccess) {
      dispatchRedux.updateStep(ACQUIRERS.SELECT);
    }
  }, []);

  return (
    <BubbleLayout>
      <Box display={'flex'} flexDirection={'column'} gridGap={24}>
        {selectorRedux.currentStep === ACQUIRERS.SELECT && (
          <>
            <FormAcquirersSelectComponent />
          </>
        )}

        {selectorRedux.currentStep === ACQUIRERS.ANTECIPATION && (
          <>
            <FormAcquirersAntecipationComponent />
          </>
        )}
      </Box>
    </BubbleLayout>
  );
};

export default AcquirersScreen;
