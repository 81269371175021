export const REGISTRATION = 'REGISTRATION';

const ACQUIRERS_SELECT = 'ACQUIRERS_SELECT';
const ACQUIRERS_ANTECIPATION = 'ACQUIRERS_ANTECIPATION';

export const ACQUIRERS = {
  SELECT: ACQUIRERS_SELECT,
  ANTECIPATION: ACQUIRERS_ANTECIPATION,
};

const TERM_ACCEPT = 'TERM_ACCEPT';

export const TERM = {
  ACCEPT: TERM_ACCEPT,
};

export const SELFIE = 'SELFIE';

const FINISH_SUCCESS = 'FINISH_SUCCESS';
const FINISH_TERM = 'FINISH_TERM';
const FINISH_LEGAL = 'FINISH_LEGAL';

export const FINISH = {
  SUCCESS: FINISH_SUCCESS,
  TERM: FINISH_TERM,
  LEGAL: FINISH_LEGAL,
};
