import fetch from '@payhop/shared-utils/fetch.util';

export default async (token: string) => {
  const response = await fetch(`accounts/auth-token`, {
    method: 'POST',
    ms: 'ACCOUNT',
    auth: false,
    body: JSON.stringify({
      token,
    }),
  });

  const result = await response.json();

  return [response.ok, result];
};
