import {
  Box,
  Button,
  CircularProgress,
  Typography,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { flow } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { ACQUIRERS } from '@wl-onboarding/constants/steps.constant';

import messages from '@payhop/shared-utils/messagesSchema.util';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';
import * as formDataDuck from '@wl-onboarding/ducks/formData.duck';
import * as acquirersDuck from '@wl-onboarding/ducks/domains/acquirers.duck';
import * as authentication from '@payhop/shared-ducks/authentication.duck';

import visalogo from '@wl-onboarding/assets/visa-logo.png';
import mastercardlogo from '@wl-onboarding/assets/mastercard-logo.png';
import hipercardlogo from '@wl-onboarding/assets/hipercard-logo.png';
import elologo from '@wl-onboarding/assets/elo-logo.png';

const validationSchema = Yup.object({
  acquirers: Yup.array()
    .min(1)
    .of(
      Yup.object({
        acquirer_id: Yup.string().required(messages.REQUIRED).nullable(),
        advancement_id: Yup.string().notRequired().nullable(),
      })
    ),
});

const FormAcquirersSelectComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
    updateData: flow(formDataDuck.Dispatchs.updateData, dispatch),
    get: flow(acquirersDuck.Dispatchs.get, dispatch),
  };

  const selectorRedux = {
    data: useSelector(acquirersDuck.Selectors.data),
    dataForm: useSelector(formDataDuck.Selectors.data),
    dataUser: useSelector(authentication.Selectors.data),
  };

  const handleContinue = async (values) => {
    dispatchRedux.updateData('acquirers', values.acquirers, { force: true });

    dispatchRedux.updateStep(ACQUIRERS.ANTECIPATION);

    return true;
  };

  React.useEffect(() => {
    dispatchRedux.get();
  }, []);

  React.useEffect(() => {
    const { acquirerConfirmed } = selectorRedux.dataUser;

    if (acquirerConfirmed === 'True') {
      dispatchRedux.updateStep(ACQUIRERS.ANTECIPATION);
    }
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={24}>
      <Typography align="center" variant="h6">
        Informe a(s) maquininha(s) com qual você trabalha. <br />
        Essas serão as credenciadoras que a PayHop pesquisará os recebíveis.
      </Typography>

      <Formik
        onSubmit={handleContinue}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnMount
        initialValues={{
          acquirers: selectorRedux?.dataForm?.acquirers,
        }}
      >
        {({ errors, submitForm, isSubmitting, values, setFieldValue }) => (
          <>
            <Box display="grid" gridTemplateColumns="1fr">
              <Box display="grid" gridTemplateColumns="1fr" gridGap={12}>
                {values.acquirers.map((v, index) => (
                  <Box display="flex" key={`acquirer-${index}`} gridGap={8}>
                    <Field
                      component={TextField}
                      name={`acquirers[${index}].acquirer_id`}
                      label="Credenciadora"
                      fullWidth
                      variant="outlined"
                      select
                      css={{ flex: 1 }}
                    >
                      {selectorRedux?.data?.map((d) => (
                        <MenuItem
                          key={d.id}
                          value={d.id}
                          disabled={values.acquirers.some(
                            (v) => v.acquirer_id === d.id
                          )}
                        >
                          {d.acquirer_name}
                        </MenuItem>
                      ))}
                    </Field>

                    {values.acquirers.length > 1 && (
                      <IconButton
                        size="small"
                        css={{ minWidth: 54 }}
                        onClick={() =>
                          setFieldValue(
                            'acquirers',
                            values.acquirers.filter((_, idx) => idx !== index)
                          )
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() =>
                    setFieldValue(
                      'acquirers',
                      values.acquirers.concat({
                        acquirer_id: null,
                        advancement_id: null,
                      })
                    )
                  }
                >
                  <AddIcon />
                  Adicionar credenciadora
                </Button>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr" gridGap={16}>
              <Typography>
                Serão realizadas pesquisas nas principais bandeiras, como Visa,
                Mastercard, Elo, entre outras.
              </Typography>
            </Box>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={Object.keys(errors).length > 0 || isSubmitting}
              onClick={submitForm}
            >
              {isSubmitting && <CircularProgress color="inherit" size={28} />}

              {!isSubmitting && <span>Continuar</span>}
            </Button>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default FormAcquirersSelectComponent;
