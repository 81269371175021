import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './store';
import Routes from './routes';

import 'moment/locale/pt-br';

import '@payhop/shared-styles/reset.style.css';
import '@payhop/shared-styles/typography.style.css';
import '@payhop/shared-styles/app.style.css';

const { store } = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
