import fetch from '@payhop/shared-utils/fetch.util';

export default (account_id: string) =>
  fetch(`checkids/sms-selfie`, {
    auth: true,
    ms: 'CHECKID',
    method: 'POST',
    body: JSON.stringify({
      account_id,
    }),
  });
