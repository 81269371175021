import { set } from 'lodash';
import flow from 'lodash/flow';
import get from 'lodash/get';

import patchMerchantsIdAcquirersService from '@wl-onboarding/services/merchant/patchMerchantsIdAcquirers.service';
import patchMerchantsIdPartnersIdService from '@wl-onboarding/services/merchant/patchMerchantsIdPartnersId.service';

import * as authentication from '@payhop/shared-ducks/authentication.duck';

const context = 'formData';
const rootPathState = context.replace(/\//g, '.');

type Actions = {
  type: string;
  payload?: any;
};

export const Types = {
  UPDATE_DATA: `${context}/UPDATE_DATA`,
  FETCH_START: `${context}/FETCH_START`,
  FETCH_END: `${context}/FETCH_END`,
  FETCH_ERROR: `${context}/FETCH_ERROR`,
  RESET_ERROR: `${context}/RESET_ERROR`,
};

const registrationObjMock = {
  full_name: 'Matheus Loureiro',
  document_number: '82812362057',
  company_position: 'Menino do frontend',
  phone_number: '85981112421',
  email: '5d8198c1d4@catdogmail.live',
  isLegal: true,
  isTrust: true,
};

const registrationObj = {
  full_name: null,
  document_number: null,
  company_position: null,
  phone_number: null,
  email: null,
  isLegal: null,
  isTrust: null,
};

const acquirersObjMock = [
  {
    acquirer_id: '438941ab-e333-431d-b8e0-793a9ad99d63',
    advancement_id: '2146a648-fa1e-46cf-b3b5-fd223bf7c153',
  },
  {
    acquirer_id: '2c3e9aab-e933-4f3f-a1d9-873f32c5f7dd',
    advancement_id: 'dc89407f-8eed-4d6f-8bca-ed0426ba4ac8',
  },
  {
    acquirer_id: '75233b98-d7f1-4a2e-b279-f6bc87e1a20f',
    advancement_id: '1e25fd85-5d32-4a78-b6f5-3c4484aad599',
  },
];

const acquirersObj = [
  {
    acquirer_id: null,
    advancement_id: null,
  },
];

const documentationObj = {
  selfie: null,
};

const initialState: any = {
  loading: null,
  error: [null, null],
  data: {
    registration: registrationObj,
    acquirers: acquirersObj,
    documentation: documentationObj,
  },
};

export default (state = initialState, action: Actions) => {
  const { type, payload } = action;

  const reducers = {
    [Types.UPDATE_DATA]: {
      data: payload,
    },
    [Types.FETCH_START]: {
      ...state,
      loading: true,
    },
    [Types.FETCH_END]: {
      ...state,
      loading: false,
      error: [false, null],
    },
    [Types.FETCH_ERROR]: {
      ...state,
      loading: false,
      error: [true, payload],
    },
    [Types.RESET_ERROR]: {
      ...state,
      error: initialState.error,
    },
  };

  return reducers[type] || state;
};

type steps = 'registration' | 'acquirers' | 'documentation';

export const updateDataDispatch =
  (step: steps, data, { force = false } = {}) =>
  (dispatch, getState) => {
    const values = { ...flow(getState, Selectors.data)() };

    set(values, step, force ? data : Object.assign({}, values[step], data));

    dispatch({
      type: Types.UPDATE_DATA,
      payload: values,
    });

    return true;
  };

export const updateAcquirersDispatch = () => async (dispatch, getState) => {
  const { personId } = flow(getState, authentication.Selectors.data)();
  const data = flow(getState, Selectors.data)();

  const acquirersArr = data.acquirers;

  const body = {
    acquirers: acquirersArr,
  };

  try {
    dispatch({
      type: Types.FETCH_START,
    });

    const [success, payload] = await patchMerchantsIdAcquirersService(
      personId,
      body
    );

    if (!success) {
      throw payload;
    }

    await dispatch(authentication.refreshToken());

    dispatch({
      type: Types.FETCH_END,
    });

    return true;
  } catch (payload) {
    dispatch({
      type: Types.FETCH_ERROR,
      payload,
    });

    return false;
  }
};

export const updatePartnersDispatch = () => async (dispatch, getState) => {
  const { personId, partnerId } = flow(
    getState,
    authentication.Selectors.data
  )();
  const data = flow(getState, Selectors.data)();

  const body = data.registration;

  try {
    dispatch({
      type: Types.FETCH_START,
    });

    const [success, payload] = await patchMerchantsIdPartnersIdService(
      { id: personId, partnerId },
      body
    );

    if (!success) {
      throw payload;
    }

    await dispatch(authentication.refreshToken());

    dispatch({
      type: Types.FETCH_END,
    });

    return true;
  } catch (payload) {
    dispatch({
      type: Types.FETCH_ERROR,
      payload,
    });

    return false;
  }
};

export const resetErrorDispatch = () => ({
  type: Types.RESET_ERROR,
});

const selectRoot = (state: any) => get(state, rootPathState);
const selectData = (state: any) => get(state, 'data');
const selectError = (state: any) => get(state, 'error');
const selectLoading = (state: any) => get(state, 'loading');

export const Selectors = {
  data: flow(selectRoot, selectData),
  error: flow(selectRoot, selectError),
  loading: flow(selectRoot, selectLoading),
};

export const Dispatchs = {
  updateData: updateDataDispatch,
  resetError: resetErrorDispatch,
  updateAcquirers: updateAcquirersDispatch,
  updatePartners: updatePartnersDispatch,
};
