import React from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  makeStyles,
} from '@material-ui/core/styles';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { newBrand } from '@payhop/shared-configs/styles.config';

import Global from '@payhop/shared-components/GlobalStyles';

import getHistoryUtil from './utils/getHistory.util';

import ROUTES from './constants/routes.constant';

import * as creditorDuck from './ducks/creditor.duck';

import RegistrationData from './screens/Registration';
import AcquirersScreen from './screens/Acquirers/Acquirers.screen';
import TermAcceptScreen from './screens/TermAccept/TermAccept.screen';
import SelfieScreen from './screens/Selfie/Selfie.screen';
import FinishScreen from './screens/Finish/Finish.screen';
import AuthByToken from './screens/AuthByToken';

import DialogShowInfosComponent from './components/Dialogs/DialogShowInfos.component';

if (process.env.REACT_APP_LOGROCKET === 'true') {
  setupLogRocketReact(LogRocket);

  LogRocket.init('28cefh/payhop');
}

const materialMakeStyles = makeStyles({
  success: { backgroundColor: newBrand.success },
});

const Routes = () => {
  const selectorRedux = {
    data: useSelector(creditorDuck.Selectors.data),
  };

  const themeObj = {
    palette: {
      primary: {
        main: selectorRedux?.data?.color_hex || newBrand.purple,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
      button: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 'normal',
      },
    },
  };

  const classes = materialMakeStyles();

  const theme = createMuiTheme(themeObj as any);

  const routes = (
    <Router history={getHistoryUtil()}>
      <Switch>
        <Route exact path={ROUTES.SELFIE} component={SelfieScreen} />
        <Route exact path={ROUTES.REGISTER} component={RegistrationData} />
        <Route exact path={ROUTES.ACQUIRERS} component={AcquirersScreen} />
        <Route exact path={ROUTES.TERM} component={TermAcceptScreen} />
        <Route exact path={ROUTES.FINISH} component={FinishScreen} />
        <Route exact path={ROUTES.AUTH_BY_TOKEN} component={AuthByToken} />

        <Redirect to={ROUTES.REGISTER} />
      </Switch>
    </Router>
  );

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={
            {
              variantSuccess: classes.success,
            } as any
          }
        >
          <Global />

          {routes}
          <DialogShowInfosComponent />
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default Routes;
