import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as authentication from '@payhop/shared-ducks/authentication.duck';

export default function useProtectedByAuth() {
  const signedIn = useSelector(authentication.Selectors.signedIn);

  useEffect(() => {
    if (!signedIn) {
      window.location.href = `/`;
    }
  }, [signedIn]);
}
