import React from 'react';
import { jsx, css } from '@emotion/react';
import { Box, IconButton, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';

import bg from '@payhop/shared-assets/bubble.png';

import Logo from '@payhop/shared-components/Logo';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import useUserAuthenticated from '@payhop/shared-hooks/useUserAuthenticated.hook';
import useIsMobile from '@payhop/shared-hooks/useIsMobile.hook';

import * as dialogShowInfos from '@wl-onboarding/ducks/dialogs/dialogShowInfos.duck';

import * as creditorDuck from '@wl-onboarding/ducks/creditor.duck';

export default function BubbleLayout({ children }) {
  const { signedIn } = useUserAuthenticated();
  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const dispatchRedux = {
    open: flow(dialogShowInfos.Dispatchs.open, dispatch),
  };

  const selectorRedux = {
    data: useSelector(creditorDuck.Selectors.data),
  };

  return (
    <Grid container>
      {!isMobile && (
        <Grid item xs={12} css={{ position: 'absolute', left: 0, top: 0 }}>
          <img
            src={selectorRedux?.data?.path_image}
            style={{ maxWidth: 240, padding: 24 }}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        css={css`
          justify-content: center;
          display: flex;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 900px;
            min-height: 100vh;
            background: url(${bg}) no-repeat center center;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              background: white;
              width: 100%;
              max-width: 513px;
              border-radius: 8px;
              box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.08);
              padding: 32px;
              position: relative;
            `}
          >
            {signedIn && (
              <Box position="absolute" top={-70} right={0}>
                <IconButton onClick={() => dispatchRedux.open({})}>
                  <AccountCircleIcon css={{ fontSize: 40 }} />
                </IconButton>
              </Box>
            )}
            {children}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
