import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Link,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { flow } from 'lodash';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ROUTES from '@wl-onboarding/constants/routes.constant';

import useNotification from '@payhop/shared-hooks/useNotification.hook';

import BubbleLayout from '@wl-onboarding/layouts/Bubble.layout';

import * as authentication from '@payhop/shared-ducks/authentication.duck';
import * as complianceDuck from '@wl-onboarding/ducks/domains/compliance.duck';

import { TERM, FINISH } from '@wl-onboarding/constants/steps.constant';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';
import useProtectedByAuth from '@wl-onboarding/hooks/useProtectedRoute.hook';

import postAccountsRejectTermService from '@wl-onboarding/services/account/postAccountsRejectTerm.service';

const TermAcceptScreen = () => {
  useProtectedByAuth();

  const acceptPartner = useRef(false);

  const handleNotification = useNotification();

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [serasaTextOpen, setSerasaTextOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const selectorRedux = {
    currentStep: useSelector(stepsDuck.Selectors.currentStep),
    dataCompliance: useSelector(complianceDuck.Selectors.data),
    loadingCompliance: useSelector(complianceDuck.Selectors.loading),
    dataUser: useSelector(authentication.Selectors.data),
  };
  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
    acceptTerm: flow(authentication.acceptTerm, dispatch),
    get: flow(complianceDuck.Dispatchs.get, dispatch),
    refreshToken: flow(authentication.refreshToken, dispatch),
  };

  useEffect(() => {
    dispatchRedux.get();

    const currentStepIsAccess = String(selectorRedux.currentStep).includes(
      'TERM'
    );

    if (!currentStepIsAccess) {
      dispatchRedux.updateStep(TERM.ACCEPT);
    }
  }, []);

  useEffect(() => {
    const { termAccepted } = selectorRedux.dataUser;

    if (termAccepted === 'True') {
      history.push(ROUTES.FINISH);
    }
  }, []);

  const handleUnaccept = async () => {
    setLoading(true);

    const response = await postAccountsRejectTermService();

    setLoading(false);

    if (response.ok) {
      dispatchRedux.updateStep(FINISH.TERM);

      history.push(ROUTES.FINISH);
    }

    if (!response.ok) {
      const result = await response.json();

      handleNotification(result.errors.message[0], 'error');
    }
  };

  const handleAccept = async () => {
    setLoading(true);

    const result: any = await dispatchRedux.acceptTerm({
      check_term_partnership: acceptPartner.current,
    });

    if (!result) {
      setLoading(false);
    }

    if (result) {
      await dispatchRedux.refreshToken();

      setLoading(false);

      return history.push(ROUTES.FINISH);
    }
  };

  const handleSerasaTextOpen = () => {
    setSerasaTextOpen(!serasaTextOpen);
  };

  const { consentimento, regulamento } = selectorRedux.dataCompliance || {};
  const { partnership } = selectorRedux?.dataUser || {};

  const isPartnershipSerasa = partnership === 'Serasa';

  return (
    <BubbleLayout>
      <Box display={'flex'} flexDirection={'column'} gridGap={24}>
        <Box display="flex" gridGap={8} flexDirection="column">
          <Typography variant="h6">
            Agora precisamos que você aceite o Termo de consentimento e o
            Regulamento da PayHop.
          </Typography>

          <Typography>
            Ler o{' '}
            <Link target="_blank" href={consentimento}>
              Termo de consentimento
            </Link>{' '}
            e o{' '}
            <Link target="_blank" href={regulamento}>
              Regulamento
            </Link>{' '}
          </Typography>
        </Box>

        <Box>
          {isPartnershipSerasa && (
            <FormControlLabel
              control={
                <Checkbox
                  name="acceptPartner"
                  color="primary"
                  onChange={({ target: { checked } }) =>
                    (acceptPartner.current = checked)
                  }
                />
              }
              label={
                <>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    css={{
                      height: serasaTextOpen ? 'auto' : '100px',
                      overflow: 'hidden',
                    }}
                  >
                    Eu autorizo a PayHop a compartilhar com a Serasa S.A.
                    (“Serasa”) as informações referentes à minha agenda de
                    recebíveis de cartões junto às Entidades Registradoras (tais
                    como{' '}
                    <Link
                      href="https://www.cerc.inf.br/"
                      target="_blank"
                      underline="none"
                    >
                      CERC
                    </Link>
                    ,{' '}
                    <Link
                      href="https://www.cip-bancos.org.br/Paginas/REGISTRADORACIP.aspx"
                      target="_blank"
                      underline="none"
                    >
                      CIP
                    </Link>{' '}
                    e{' '}
                    <Link
                      href="https://taginfraestrutura.com.br/"
                      target="_blank"
                      underline="none"
                    >
                      TAG
                    </Link>
                    ) autorizadas pelo Banco Central do Brasil, conforme
                    Resolução 4.734/19, para que a Serasa e as empresas do seu
                    grupo econômico possam utilizar em desenvolvimentos internos
                    e atingir uma ou mais das seguintes finalidades: (i) análise
                    e uso dos dados da Empresa (inclusive de recebíveis) para
                    fins de score de riscos de crédito, formação de perfis
                    (inclusive de crédito), aprimoramento de pontuações e
                    categorizações da Empresa e/ou atividades de prevenção de
                    fraudes, proteção ao crédito, aprimoramento de scores,
                    criação e oferta de novos produtos e soluções para o mercado
                    financeiro para fins de crédito e serviços de meios de
                    pagamento contendo as análises de dados da Empresa; (ii)
                    utilização dos dados da Empresa (incluindo recebíveis) como
                    um dos parâmetros na categorização (determinação de notas,
                    score) e determinação de perfis da Empresa para inclusão em
                    produtos de marketing a serem oferecidos aos clientes da
                    Serasa, como por exemplo, nos produtos de segmentação de
                    empresas e campanhas, clientes da Serasa terão acesso a
                    perfis, notas, pontuações e scores da Empresa; e (iii)
                    desenvolvimento e oferta de produtos pela Serasa à Empresa
                    e/ou ao mercado, com a inclusão dos resultados das análises
                    de informações da Empresa e/ou dados da Empresa obtidos
                    junto às Entidades Registradoras, tais como perfis, notas,
                    pontuações e scores da Empresa.
                  </Typography>
                  <Button
                    onClick={handleSerasaTextOpen}
                    css={{ padding: 0, display: 'inline' }}
                  >
                    <Typography variant="body1" color="primary">
                      ...ver {serasaTextOpen ? 'menos' : 'mais'}
                    </Typography>
                  </Button>
                </>
              }
            />
          )}
        </Box>

        <Box display="flex" flexDirection={'column'} gridGap={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleAccept}
            disabled={loading || selectorRedux.loadingCompliance}
          >
            {(loading || selectorRedux.loadingCompliance) && (
              <CircularProgress color="inherit" size={28} />
            )}

            {!(loading || selectorRedux.loadingCompliance) && (
              <span>Aceito</span>
            )}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setModal(true)}
            disabled={loading || selectorRedux.loadingCompliance}
          >
            {(loading || selectorRedux.loadingCompliance) && (
              <CircularProgress color="inherit" size={28} />
            )}

            {!(loading || selectorRedux.loadingCompliance) && (
              <span>Não aceito</span>
            )}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Você tem certeza?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Para prosseguir com o seu cadastro na PayHop e poder utilizar os
            seus recebíveis das vendas com cartão como garantia de pagamento
            para <strong>comprar mais</strong> e ter{' '}
            <strong>mais prazo para pagamento</strong>, precisamos que você leia
            e aceite o{' '}
            <Link target="_blank" href={consentimento}>
              Termo de consentimento
            </Link>{' '}
            e{' '}
            <Link target="_blank" href={regulamento}>
              Regulamento
            </Link>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnaccept} color="primary">
            {(loading || selectorRedux.loadingCompliance) && (
              <CircularProgress color="inherit" size={28} />
            )}

            {!(loading || selectorRedux.loadingCompliance) && <span>Sim</span>}
          </Button>
          <Button onClick={() => setModal(false)} color="primary" autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </BubbleLayout>
  );
};

export default TermAcceptScreen;
