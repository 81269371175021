type msObject = {
  ACCOUNT: string | undefined;
  MERCHANT: string | undefined;
  DEAL: string | undefined;
  NOTIFICATION: string | undefined;
  DOMAIN: string | undefined;
  CHECKID: string | undefined;
  REQUEST: string | undefined;
  CONTRACT: string | undefined;
  SCHEDULE: string | undefined;
  CREDITOR: string | undefined;
  EXPORT: string | undefined;
  UPLOAD: string | undefined;
  RECON_IMPORT: string | undefined;
  RECON_ACCOUNT: string | undefined;
  RECON_DOMAIN: string | undefined;
  RECON_MATCH: string | undefined;
  RECON_MONITORING: string | undefined;
  API: string | undefined;
};

export const MS: msObject = {
  ACCOUNT: process.env.REACT_APP_ACCOUNT_MS,
  MERCHANT: process.env.REACT_APP_MERCHANT_MS,
  DEAL: process.env.REACT_APP_DEAL_MS,
  NOTIFICATION: process.env.REACT_APP_NOTIFICATION_MS,
  DOMAIN: process.env.REACT_APP_DOMAIN_MS,
  CHECKID: process.env.REACT_APP_CHECKID_MS,
  REQUEST: process.env.REACT_APP_REQUEST_MS,
  CONTRACT: process.env.REACT_APP_CONTRACT_MS,
  SCHEDULE: process.env.REACT_APP_SHCEDULE_MS,
  CREDITOR: process.env.REACT_APP_CREDITOR_MS,
  EXPORT: process.env.REACT_APP_EXPORT_MS,
  UPLOAD: process.env.REACT_APP_UPLOAD_MS,
  RECON_IMPORT: process.env.REACT_APP_RECON_IMPORT_MS,
  RECON_ACCOUNT: process.env.REACT_APP_RECON_ACCOUNT_MS,
  RECON_DOMAIN: process.env.REACT_APP_RECON_DOMAIN_MS,
  RECON_MATCH: process.env.REACT_APP_RECON_MATCH_MS,
  RECON_MONITORING: process.env.REACT_APP_RECON_MONITORING_MS,
  API: process.env.REACT_APP_API,
};

const PATH = 'api';

export const API_URL_WITH_MS = (
  ms:
    | 'ACCOUNT'
    | 'MERCHANT'
    | 'DEAL'
    | 'NOTIFICATION'
    | 'DOMAIN'
    | 'CHECKID'
    | 'REQUEST'
    | 'CONTRACT'
    | 'CREDITOR'
    | 'SCHEDULE'
    | 'EXPORT'
    | 'UPLOAD'
    | 'RECON_IMPORT'
    | 'RECON_ACCOUNT'
    | 'RECON_DOMAIN'
    | 'RECON_MONITORING'
    | 'RECON_MATCH'
    | 'API'
): string => `${MS[ms]}/${PATH}`;

export const WSS_NOTIFICATION_URL = `${MS.NOTIFICATION}`;
