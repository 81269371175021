import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { flow } from 'lodash';

import BubbleLayout from '@wl-onboarding/layouts/Bubble.layout';

import { SELFIE } from '@wl-onboarding/constants/steps.constant';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';

import FormDocumentationSelfieComponent from '@wl-onboarding/components/Form/FormDocumentation/FormDocumentationSelfie.component';

import useProtectedByAuth from '@wl-onboarding/hooks/useProtectedRoute.hook';

const DocumentationScreen = () => {
  useProtectedByAuth();

  const dispatch = useDispatch();

  const selectorRedux = {
    currentStep: useSelector(stepsDuck.Selectors.currentStep),
  };

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
  };

  useEffect(() => {
    const currentStepIsAccess = String(selectorRedux.currentStep).includes(
      SELFIE
    );

    if (!currentStepIsAccess) {
      dispatchRedux.updateStep(SELFIE);
    }
  }, []);

  return (
    <BubbleLayout>
      <Box display={'flex'} flexDirection={'column'} gridGap={24}>
        <FormDocumentationSelfieComponent />
      </Box>
    </BubbleLayout>
  );
};

export default DocumentationScreen;
