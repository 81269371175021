import fetch from '@payhop/shared-utils/fetch.util';

type Body = {
  full_name: string;
  document_number: string;
  company_position: string;
  email: string;
  active: boolean;
};

export default async function patchMerchantsIdPartnersIdService(
  { id, partnerId },
  payload: Body
) {
  const url = [`merchants/${id}/partners/${partnerId}`].join('');

  const response = await fetch(url, {
    method: 'PATCH',
    ms: 'MERCHANT',
    auth: true,
    body: JSON.stringify(payload),
  });

  try {
    const result = await response.json();

    return [response.ok, result];
  } catch (e) {
    return [response.ok, {}];
  }
}
