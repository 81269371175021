import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import LogRocket from 'logrocket';

import authentication from '@payhop/shared-ducks/authentication.duck';
import error from '@payhop/shared-ducks/error.duck';

import steps from '@wl-onboarding/ducks/steps.duck';
import formData from '@wl-onboarding/ducks/formData.duck';
import domains from '@wl-onboarding/ducks/domains';
import dialogs from '@wl-onboarding/ducks/dialogs';
import creditor from '@wl-onboarding/ducks/creditor.duck';

const shared = combineReducers({
  authentication,
  error,
});

const appReducer = combineReducers({
  shared,
  steps,
  formData,
  domains,
  dialogs,
  creditor,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const composeWithReduxDevTools =
  (window as any).window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, LogRocket.reduxMiddleware()];

const composedEnhancers = composeWithReduxDevTools(
  applyMiddleware(...middlewares)
);

const store = createStore(rootReducer, composedEnhancers);

const persistor = persistStore(store);

export default () => ({
  store,
  persistor,
});
