import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { flow } from 'lodash';

import BubbleLayout from '@wl-onboarding/layouts/Bubble.layout';

import { REGISTRATION } from '@wl-onboarding/constants/steps.constant';

import FormRegistrationDataDocumentComponent from '@wl-onboarding/components/Form/FormRegistration/FormRegistration.component';

import * as stepsDuck from '@wl-onboarding/ducks/steps.duck';
import * as creditorDuck from '@wl-onboarding/ducks/creditor.duck';

const RegistrationDataScreen = () => {
  const dispatch = useDispatch();

  const selectorRedux = {
    currentStep: useSelector(stepsDuck.Selectors.currentStep),
  };

  const selectorCreditorRedux = {
    data: useSelector(creditorDuck.Selectors.data),
  };

  const dispatchRedux = {
    updateStep: flow(stepsDuck.Dispatchs.updateStep, dispatch),
  };

  React.useEffect(() => {
    const currentStepIsAccess = String(selectorRedux.currentStep).includes(
      REGISTRATION
    );

    if (!currentStepIsAccess) {
      dispatchRedux.updateStep(REGISTRATION);
    }
  }, []);

  return (
    <>
      {selectorCreditorRedux?.data?.creditor_id && (
        <BubbleLayout>
          <Box display={'flex'} flexDirection={'column'} gridGap={24}>
            <FormRegistrationDataDocumentComponent />
          </Box>
        </BubbleLayout>
      )}
      {!selectorCreditorRedux?.data?.creditor_id && (
        <Box
          display={'flex'}
          height="100vh"
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography>Estabelecimento não encontrado</Typography>
        </Box>
      )}
    </>
  );
};

export default RegistrationDataScreen;
